import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { getSessionGlobal, storeData } from '../helper/session/SessionGlobal'
import { apiLogin } from '../api/HomeApi';
import { TextInput } from 'react-native-gesture-handler';
import { TouchableOpacity } from 'react-native-web';
import { $light, $primary, $white } from '../helper/Constants';
import MyButton from '../components/MyButton';

export default function Home({route, navigation}) {
    const [username, set_username] = useState('');
    const [password, set_password] = useState('');
    const [session_token, set_session_token] = useState('');

    const login = async () => {
      storeData('username', 'acong');
      const userx =  await getSessionGlobal('username');
      console.log(userx);
      set_username(userx);
      const res = await apiLogin({
      username: username,
      password: password,
       });

      //  console.log('res res login', res);

      storeData('sessionToken', res.sessionToken);
      storeData('objectId', res.objectId);
      storeData('username', res.username);
      storeData('password', password);
      storeData('email', res.email);
      storeData('profileName', res.profileName);
      storeData('phone', res.phone);
      navigation.navigate('dashboard')
    }

    const checkUser = async () => {
      const sessionToken =  await getSessionGlobal('sessionToken');

      if ( sessionToken ) {
        navigation.navigate('dashboard')
      }
    }

    useEffect( () => {
      checkUser();
    }, [])
  return (
    <View
      style={[
        mainStyles.container
      ]}
    >
      <Text>Login As '{username}'</Text>

      <View style={[
        {
          width: 250,
        }
      ]}
      >
        <View
          style={[
            mainStyles.formGroup
          ]}
        >
          <Text>Username</Text>
          <TextInput 
            keyboardType='default'
            placeholder='Username'
            onChangeText={ (text) => set_username(text)}
            style={[
              mainStyles.formInput
            ]}
          />
        </View>

        <View
          style={[
            mainStyles.formGroup
          ]}
        >
          <Text>Password</Text>
          <TextInput 
            secureTextEntry={true}
            keyboardType='default'
            placeholder='Password'
            onChangeText={ (text) => set_password(text)}
            style={[
              mainStyles.formInput
            ]}
          />
        </View>

        <View
          style={[
            mainStyles.formGroup
          ]}
        >
          <MyButton btn='primary' title={'LOGIN'} onPress={ login } />
        </View>
      </View>
    </View>
  )
}

const mainStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: $light
  },
  formGroup: {
    marginBottom: 15,
  },
  formInput: {
    borderWidth: 1,
    padding: 15,
    marginTop: 5,
    borderRadius: 7.5
  }
})