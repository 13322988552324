import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { $danger, $primary, $secondary, $white } from '../helper/Constants'
import { TouchableOpacity } from 'react-native-gesture-handler'

export default function MyButton({title, onPress, btn }) {

    if ( btn == 'primary' ) {
        return (
          <TouchableOpacity
          onPress={onPress}
          style={[
              {
              backgroundColor: $primary,
              padding: 15,
              borderWidth: 1,
              borderColor: $primary,
              borderRadius: 7.5,
              }
          ]}
          >
              <Text style={{ color: $white, textAlign: 'center' }}>{title}</Text>
          </TouchableOpacity>
        )
    }

    if ( btn == 'secondary' ) {
        return (
          <TouchableOpacity
          onPress={onPress}
          style={[
              {
              backgroundColor: $secondary,
              padding: 15,
              borderWidth: 1,
              borderColor: $secondary,
              borderRadius: 7.5,
              }
          ]}
          >
              <Text style={{ color: $white, textAlign: 'center' }}>{title}</Text>
          </TouchableOpacity>
        )
    }

    if ( btn == 'danger' ) {
        return (
          <TouchableOpacity
          onPress={onPress}
          style={[
              {
              backgroundColor: $danger,
              padding: 15,
              borderWidth: 1,
              borderColor: $danger,
              borderRadius: 7.5,
              }
          ]}
          >
              <Text style={{ color: $white, textAlign: 'center' }}>{title}</Text>
          </TouchableOpacity>
        )
    }
}

const styles = StyleSheet.create({})