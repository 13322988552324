export const formGroup= {
    marginBottom: 15,
  };


export const formInput= {
    borderWidth: 1,
    padding: 15,
    marginTop: 5,
    borderRadius: 7.5
  };