import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getSessionGlobal, removeAll } from '../helper/session/SessionGlobal';
import { TextInput } from 'react-native-gesture-handler';
import { formGroup, formInput } from '../helper/GlobalStyles';
import { apiGetChat, apiGetLastMessage, apiSendMessage } from '../api/HomeApi';
import MyButton from '../components/MyButton';

export default function Dashboard({route,navigation}) {
    const [interval_id, set_interval_id] = useState('');
    const [respon_ms, set_respon_ms] = useState(15000);

    const [session_token, set_session_token] = useState('');
    const [object_id, set_object_id] = useState('');
    const [profile_name, set_profile_name] = useState('');

    const [link_room, set_link_room] = useState('');

    const getUserSession = async () => {
      const sessionToken =  await getSessionGlobal('sessionToken');
      const objectId =  await getSessionGlobal('objectId');
      const profileName =  await getSessionGlobal('profileName');

      navigation.setOptions({
        headerTitle: profileName
      })

      set_session_token(sessionToken);
      set_object_id(objectId);
      set_profile_name(profileName);


    }

    const handleGetLastMessage = async () => {
        const room_id = link_room.slice(-10)
        const res = await apiGetLastMessage({
            dialogueId: link_room.slice(-10),
            session_token: session_token
        })

        console.log('apiGetLastMessage', res);
        console.log('last message', res.result.messages[0].message);
        console.log('last sender id', res.result.messages[0].senderId);

        if ( object_id == res.result.messages[0].senderId ) {
            return false;
        } else {
            const resGetChat = await apiGetChat({
                room_id: room_id,
                chat: res.result.messages[0].message,
            });
            console.log("get data respon message from server", resGetChat);
            if ( resGetChat.code == '200' ) {
                
                const resSendMessage = await apiSendMessage({
                    'dialogue': room_id,
                    'message': resGetChat.msg_to_ac,
                    session_token: session_token,
                })
                console.log("success apiSendMessage", resSendMessage);
            } else {
                console.log("gagal apiSendMessage", resSendMessage);
            }
        }
        
    }

    function runFunctionPeriodically(func, interval) {
        let intervalID = setInterval(func, interval);
        set_interval_id(intervalID);
    }

    function stopInterval(intervalID) {
        clearInterval(intervalID);
        set_interval_id('');
    }
      
    // Contoh penggunaan
    function myFunction() {
        console.log("Hello World");
    }

    const handleStop = () => {
        stopInterval(interval_id)
    }

    const handleStart = () => {
        if ( link_room.length > 9 ) {
            runFunctionPeriodically(handleGetLastMessage, respon_ms);
        } else {
            alert("Invalid Link Group");
            return false;
        }
    }

    const handleStartTest = async () => {
        const room_id = link_room.slice(-10)

        const resSendMessage = await apiSendMessage({
            'dialogue': room_id,
            'message': "Test",
            session_token: session_token
        })
    }

    const handleLogout = async () => {
        const res = await removeAll();
        navigation.navigate('Home');
    }

    useEffect( () => {
        getUserSession();
    }, [])


  return (
    <View style={{ flex: 1, padding: 15}}>
        <View
          style={[
           formGroup
          ]}
        >
            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <View style={{ width: '40%'}}>
                    <Text>Link Group {link_room.slice(-10)}</Text>
                    <TextInput 
                        keyboardType='default'
                        placeholder='Link Group'
                        onChangeText={ (text) => set_link_room(text)}
                        style={[
                        formInput
                        ]}
                    />
                </View>

                <View style={{ width: '40%'}}>
                    <Text>Respon Bot</Text>
                    <TextInput 
                        keyboardType='numeric'
                        placeholder='Mili Second Default 15.000 Alias 15detik' 
                        onChangeText={ (text) => set_respon_ms(text)}
                        value={respon_ms}
                        style={[
                        formInput
                        ]}
                    />
                </View>
            </View>
        </View>

        <View
          style={[
           formGroup
          ]}
        >
            {
                interval_id == ''?
                <>
                    <MyButton btn={'primary'} title={'START'} onPress={handleStart} />
                </>
                :
                <>
                    <MyButton btn={'danger'} title={'STOP'} onPress={handleStop} />
                </>
            }
            
            <View style={{ marginTop: 15}}>
                <MyButton btn={'secondary'} title={'LOGOUT'} onPress={handleLogout} />
            </View>

        </View>
    </View>
  )
}

const styles = StyleSheet.create({})