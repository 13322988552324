import axios from "axios";
import { $_ApplicationId, $_method, $url_api_ac, $url_api_server } from "../helper/Constants";

export async  function apiLogin(obj) {
    try {

        const res = await axios({
            method: 'post',
            url: $url_api_ac+'/login',
            headers: {
              "Content-Type": "application/json", 
            },
            data: {
              username: obj.username,
              password: obj.password,
              '_method': $_method,
              '_ApplicationId': $_ApplicationId,
            }
        })
        
        console.log('res login', res)

        if (res.data.code == "200") {
            return res.data;
        } else {
            return res.data;
            alert(res.data.message)
        }
        
    } catch (error) {
        alert("Terjadi Kesalahan")
        console.log(error);
        return error;
    }
}

export async  function apiSendMessage(obj) {
    try {

        const res = await axios({
            method: 'post',
            url: $url_api_ac+'classes/Messages',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: {
            //   username: obj.username,
            //   password: obj.password,
              'dialogue': obj.dialogue,
              'message': obj.message,
              '_ApplicationId': $_ApplicationId,
              '_SessionToken': obj.session_token,
            }
        })
        
        console.log('res login', res)

        // let data = {
        //     'dialogue': obj.dialogueId,
        //     'message': 'sepi bgt test',
        //     '_SessionToken': obj.session_token, 
        //     '_ApplicationId': $_ApplicationId,
        //   };
          
        //   let config = {
        //     method: 'post',
        //     maxBodyLength: Infinity,
        //     url: 'https://mobile-elb.antich.at/classes/Messages',
        //     headers: { 
        //       'Content-Type': 'application/x-www-form-urlencoded', 
        //       'Cookie': 'incap_ses_7265_1287212=K7aEENkUDii+GWhQWHfSZMMcV2QAAAAAialdifpbjGhtglkdUSEEJw==; nlbi_1287212=iTNUf9U1KnJ5qJJwRUeI+AAAAAALUiPFCkNxMfK/Gg1Nwar5; visid_incap_1287212=ZAuxBr3vQfaKAjTaHwQE4abIVWQAAAAAQUIPAAAAAAAaqekXkeVmBr9GENIS3MQk'
        //     },
        //     data : data
        //   };

        if (res.data.code == "200") {
            return res.data;
        } else {
            return res.data;
            alert(res.data.message)
        }
        
    } catch (error) {
        alert("Terjadi Kesalahan")
        console.log(error);
        return error;
    }
}



export async  function apiGetLastMessage(obj) {
    try {

        const res = await axios({
            method: 'post',
            url: $url_api_ac+'/functions/getMessagesAndRemoves',
            headers: {
              "Content-Type": "application/json", 
            },
            data: {
            //   username: obj.username,
            //   password: obj.password,
              'dialogueId': obj.dialogueId,
            //   'message': obj.message,
            //   '_method': $_method,
              '_ApplicationId': $_ApplicationId,
              '_SessionToken': obj.session_token,
            }
        })
        
        console.log('res login', res)

        if (res.data.code == "200") {
            return res.data;
        } else {
            return res.data;
            alert(res.data.message)
        }
        
    } catch (error) {
        alert("Terjadi Kesalahan")
        console.log(error);
        return error;
    }
}


export async  function apiGetChat(obj) {
    try {

        const res = await axios({
            method: 'post',
            url: $url_api_server+'getChat',
            headers: {
              "Content-Type": "application/json", 
            },
            data: {
              'room_id': obj.room_id,
              'chat': obj.chat,
            }
        })
        
        console.log('apiGetChat', res)

        if (res.data.code == "200") {
            return res.data;
        } else {
            return res.data;
            alert(res.data.message)
        }
        
    } catch (error) {
        alert("Terjadi Kesalahan")
        console.log(error);
        return error;
    }
}