// In App.js in a new project

import * as React from 'react';
import { View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from './screens/Home';
import Dashboard from './screens/Dashboard';


const Stack = createNativeStackNavigator();

function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen name="Home" component={Home} options={{
          headerTitle: 'Login'
        }} />
        <Stack.Screen name="dashboard" component={Dashboard} options={{
          headerTitle: 'Dashboard',
          headerLeft: false
        }} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default App;